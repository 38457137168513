import React, { useContext, useState, useRef, useEffect } from 'react';
import { ChatbotContext } from '../../context/ChatbotContext';
import MessageIcon from '../../assets/svgs/MessageIcon';
import ChevronDown from '../../assets/svgs/ChevronDown';
import CloseIcon from '../../assets/svgs/CloseIcon';
import './Chatbot.css';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [popupMessagesOpen, setPopupMessagesOpen] = useState(true)
  const [showClosePopup, setShowClosePopup] = useState(false)
  const assistant = useContext(ChatbotContext);
  const iframeRef = useRef(null);

  const domain = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://aii-chatfull-riij5.ondigitalocean.app';


  const toggleChat = () => {
    console.log('toggleChat', isOpen);
    setIsOpen(!isOpen);
    setPopupMessagesOpen(false);
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // console.log('event.origin', event.origin);
      // console.log('event.data', event.data);
      if (event.origin !== domain) return;
      if (event.data === "closeChatbot") {
        setIsOpen(false);
      } else if (event.data === "openChatbot") {
        setIsOpen(true);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [domain]);

  const dynamicStyle = {
    backgroundImage: (assistant.image_url && !isOpen) ? `url(${assistant.image_url})` : 'none',
    backgroundColor: assistant.color ? assistant.color : 'none'
  };


  return (
    <div className="chatbot-container">
      <div className={`chatbot-window ${isOpen ? 'open' : 'closed'}`}>
        <iframe
          ref={iframeRef}
          id="chatbot-iframe"
          title='chatbot-iframe'
          src={`${domain}/full/${assistant.slug}`}
          style={{ display: isOpen ? 'block' : 'none', border: 'none' }}
        >
        </iframe>
      </div>
      <div
        className="chatbot-message-container"
        style={!popupMessagesOpen ? { display: 'none' } : undefined}
        onMouseEnter={() => setShowClosePopup(true)}
        onMouseLeave={() => setShowClosePopup(false)}
      >
        {
          (assistant.preloaded_messages.length > 0 && !isOpen) && (
            <>
              {showClosePopup &&
                <button className="chatbot-message-close-button" onClick={() => setPopupMessagesOpen(false)}>
                  <CloseIcon />
                </button>
              }
              {
                assistant.preloaded_messages.map((message: any) => (
                  <div key={message.id} className="chatbot-message" onClick={toggleChat}><p>{message}</p></div>
                ))
              }
            </>

          )
        }
      </div>
      <div className="chatbot-bubble-container">
        <button className="chatbot-bubble" style={dynamicStyle} onClick={toggleChat} aria-label={`Chat with ${assistant.name} AI`}>
          {!assistant.image_url && <MessageIcon />}
          {isOpen && <ChevronDown />}
        </button>
      </div>
    </div>
  );
};

export default Chatbot;
